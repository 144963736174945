<template>
  <v-dialog
    content-class="dialog-rounded"
    v-model="showAdd"
    width="500"
    persistent
    @keydown.esc="onClose"
    @keydown.enter="saveUserBanner"
  >
    <template v-slot:activator="{ on }">
      <v-btn @click="onNewClicked" class="button white" elevation="0" icon small v-on="on">
         <prosk-icon icon="camera" />
      </v-btn>
    </template>

    <details-card scrollable>
      <v-card-title>
        <prosk-title>
          {{ $t("pickBannerImage") }}
        </prosk-title>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12">
              <v-file-input
                class="custom-file-field image-input"
                accept="image/*"
                @change="handleFileUpload"
                :placeholder="$t('noSelectedFiles')"
                prepend-icon=""
                v-model="banner_img"
                :error-count="errors.length"
                :error-messages="errors"
                ref="bannerInput"
              >
                <template v-slot:prepend-inner>
                  <button class="file-button main_text_color--text" @click="fileInputClick('bannerInput')">
                    <v-icon size="16px">mdi-camera</v-icon>
                    <span>{{ $t('clickToAddImage') }}</span>
                  </button>
                </template>
              </v-file-input>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions class="dialog-actions">
        <v-spacer></v-spacer>
        <general-button :action="onClose" outlined :message="$t('cancel')"/>
        <general-button
          v-if="canSaveImage"
          :action="saveUserBanner"
          :loading="loading"
          :message="$t('save')"
        />
      </v-card-actions>
    </details-card>
  </v-dialog>
</template>

<script>
import axios from 'axios';
import { mapActions, mapGetters } from 'vuex';
import errorDialog from '@/components/socialvue/mixins/errorDialog';
import verifyImageSize from '@/components/socialvue/mixins/verifyImageSize';
import ProskIcon from '@/icons/ProskIcon.vue';
import DetailsCard from '@/components/socialvue/cards/DetailsCard.vue';
import GeneralButton from '@/components/socialvue/buttons/GeneralButton.vue';
import ProskTitle from '@/components/socialvue/markup/ProskTitle.vue';

export default {
  name: 'DialogUserBanner',
  components: { ProskIcon, DetailsCard, GeneralButton, ProskTitle },
  props: ['showAdd', 'onClose', 'newItem', 'isAdminEditing', 'userId'],
  mixins: [errorDialog, verifyImageSize],
  data () {
    return {
      banner_img: null,
      loading: false,
      errors: []
    };
  },
  computed: {
    ...mapGetters({
      currentUser: 'auth/currentUser'
    })
  },
  methods: {
    ...mapActions({
      updateUser: 'auth/updateUser',
      updatePercentage: 'auth/updatePercentage'
    }),
    onNewClicked () {
      this.resetErrors();
      this.resetUserBanner();
      this.newItem();
    },
    resetUserBanner () {
      this.banner_img = null;
    },
    resetErrors () {
      this.errors = [];
    },
    getFormData () {
      const formData = new FormData();
      if (this.banner_img != null) {
        formData.append('banner_img', this.banner_img);
      }
      return formData;
    },
    saveUserBanner () {
      this.resetErrors();
      this.loading = true;
      const formData = this.getFormData();
      const userId = this.isAdminEditing ? this.userId : this.currentUser.id;
      const url =
        process.env.VUE_APP_BASE_URL +
        `/api/users/${userId}/medias`;
      if (this.banner_img == null) {
        this.errors.push(this.$t('photoFieldRequired'));
        this.loading = false;
      } else {
        axios
          .post(url, formData)
          .then(() => {
            this.updateUser(userId);
            if (this.isAdminEditing) {
              this.updateUser(this.currentUser.id);
            }
            this.updatePercentage(userId);
            this.resetUserBanner();
            this.loading = false;
            this.onClose();
          })
          .catch((error) => {
            this.loading = false;
            this.showError(error);
          });
      }
    }
  }
};
</script>
<style scoped>
.button {
  height: 40px;
  margin: 20px;
  padding: 9px;
  width: 40px;
}
</style>
