<template>
<v-dialog v-model="showDialog" width="600" persistent @keydown.esc="onClose">
  <delete-warning
    :confirm-delete="deletePost"
    :on-close="onDeleteClose"
    :show-delete="showDelete"
    :loading="loadingPosts"
  />
  <details-card>
    <v-card>
        <v-card-title class="p-4 text-h5 bg-prosk-secondary mb-4 d-flex justify-content-between ">
            <span class="headline">
                {{ $t("posts") }}
            </span>
            <cancel-button class="ml-4" :cancel="onClose" :message="$t('cancel')" />
        </v-card-title>
          <v-card-text v-if="!loadingPostsList && posts.length === 0">
            <v-card elevation="0" class="round">
              <v-card-text>
                <v-row>
                  <v-col cols="12">
                    <alert :message="$t('noPosts')"/>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
        </v-card-text>
        <v-card elevation="0"
          v-for="(item, index) in posts" :key="index"
          class="round"
        >
          <v-card-text>
            <v-row>
              <v-col cols="12" class="d-flex justify-content-between">
                <div>
                  <div class="d-flex align-items-center">
                    <img
                      v-if="!userData.profile_img"
                      src="@/assets/images/page-img/avatar.png"
                      class="avatar-60 me-3 rounded-circle img-fluid"
                      width="70"
                      alt="user"
                    />
                    <img
                      v-else
                      :src="userData.profile_img"
                      class="avatar-60 me-3 rounded-circle img-fluid"
                      alt="user"
                      width="70"
                      height="70"
                    />
                    <div class="text-left">
                      <p class="ma-0 font-weight-bold secondary-prosk">{{ userData.username }}</p>
                      <span class="secondary-prosk">{{ getPostHour(item.created_at) }}</span>
                    </div>
                  </div>
                </div>
                <div>
                  <v-menu transition="slide-y-transition" left>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn icon v-bind="attrs" v-on="on" class="custom-btn">
                        <v-icon class="secondary-prosk">mdi-dots-vertical</v-icon>
                      </v-btn>
                    </template>
                    <v-list>
                      <v-list-item dense ripple @click="openWarning(item.id)">
                        <v-btn icon x-small class="float-left custom-btn">
                          <v-icon> mdi-delete </v-icon>
                        </v-btn>
                        {{ $t("delete") }}
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </div>
              </v-col>
            </v-row>
            <v-divider></v-divider>
            <v-row class="px-6 text-left">
              <v-col cols="12" class="pb-0">
                <h5 class="mb-2 secondary-prosk" v-if="item.title">{{ item.title }}</h5>
              </v-col>
            </v-row>
            <v-row class="px-6 text-left">
              <v-col cols="12" class="py-0">
                <p class="mt-0 mb-2 secondary-prosk" v-if="item.content">{{ item.content }}</p>
              </v-col>
            </v-row>
            <v-row class="px-6">
              <v-col cols="12">
                <img
                  v-if="item.url_image"
                  :src="item.url_image"
                  alt="post-image"
                  class="img-fluid w-100 round"
                />
              </v-col>
            </v-row>
            <v-row class="px-6">
              <v-col cols="12">
                <video
                  class="w-100"
                  id="video-preview"
                  controls
                  v-if="item.url_video"
                >
                  <source :src="item.url_video" />
                </video>
              </v-col>
            </v-row>
            <v-row class="margin-y" align="center">
              <v-col class="secondary-prosk cursor-pointer">
                <span>
                  {{ `${(item.comments && item.comments.length) || 0} ${$t('comments')}` }}
                </span>
                <button
                  @click="toggleShowComments(item)"
                  class="ml-5 secondary-prosk cursor-pointer font-weight-bold"
                  v-show="item.comments && item.comments.length > 2"
                >
                  {{ $t('showAll') }}
                </button>
              </v-col>
            </v-row>
            <v-card outlined elevation="3" v-if="loadingPostsList" class="round">
              <v-card-text>
                <v-row>
                  <v-col cols="12">
                    <v-skeleton-loader
                    v-for="index in 3"
                    :key="index"
                    v-bind="skeletonAttrs"
                    type="list-item-avatar, divider, list-item-three-line, card-heading, actions">
                    </v-skeleton-loader>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-card-text>
        </v-card>
        <v-main class="text-center mt-5 pb-10" v-if="!hideMorePostsBtn">
          <v-btn
            x-small
            plain
            link
            color="teal darken-4"
            :disabled="loadingPostsList"
            @click="getPosts(true)"
            class="custom-btn"
          >
          <v-icon class="secondary-prosk">mdi-cached</v-icon>
          <span class="ml-2 secondary-prosk">{{ $t("loadOldPosts") }}</span>
        </v-btn>
      </v-main>
    </v-card>
  </details-card>
</v-dialog>
</template>

<script>
  import axios from 'axios';
  import Alert from '@/components/socialvue/alerts/Alert.vue';
  import moment from 'moment';
  import CancelButton from '@/components/socialvue/buttons/CancelButton.vue';
  import DetailsCard from '@/components/socialvue/cards/DetailsCard.vue';
  import DeleteWarning from '@/components/socialvue/dialogs/DeleteWarning';
  import errorDialog from '@/components/socialvue/mixins/errorDialog';
  export default {
    name: 'DeletePostAdminDialog',
    props: ['showDialog', 'userData', 'onClose', 'isLoading'],
    components: {
      DetailsCard, CancelButton, DeleteWarning, Alert
    },
    mixins: [ errorDialog ],
    data () {
        return {
          contentErrors: [],
          loading: false,
          title: '',
          content: '',
          url_image: null,
          url_video: null,
          url: null,
          user_id: null,
          loadingPostsList: true,
          loadingPosts: false,
          posts: [],
          showDelete: false,
          postIdToDelete: '',
          page: 1,
          hideMorePostsBtn: false,
          commentIdToDelete: '',
          skeletonAttrs: {
            class: 'mb-6',
            elevation: 0
          }
        };
    },
    created () {
      this.getPosts();
    },
    methods: {
      openWarning (item) {
        this.showDelete = true;
        this.postIdToDelete = item;
      },
      openCommentWarning (item, comment) {
        this.showCommentDelete = true;
        this.postIdToDelete = item;
        this.commentIdToDelete = comment;
      },
      getPostHour (hour) {
        const originalDate = new Date(hour);
        return moment(moment.utc(originalDate)).format('DD/MM/YYYY HH:mm');
      },
      getPosts (more) {
        if (more) {
          this.page++;
        }
        this.loadingPostsList = true;
        const url = process.env.VUE_APP_BASE_URL + `/api/posts?filters=user_id=${this.userData.id}&page=${this.page}&sortBy=id,desc`;
        axios.get(url).then((response) => {
          this.posts = this.posts.concat(response.data.data);
          this.posts = this.posts.map(post => {
            return post;
          });
          this.loadingPostsList = false;
          this.hideMorePostsBtn = (this.page === response.data.last_page);
        }).catch((error) => {
            this.showError(error);
            this.loadingPostsList = false;
          });
      },
      toggleShowComments (item) {
        item.showComments = !item.showComments;
        this.forceRender = !this.forceRender;
      },
      onDeleteClose () {
        this.showDelete = false;
      },
      deletePost () {
        this.loadingPosts = true;
        const url =
          process.env.VUE_APP_BASE_URL + '/api/posts/' + this.postIdToDelete;
        axios
          .delete(url)
          .then(() => {
            console.log('Deleting post with ID:', this.postIdToDelete);
            // this.updatePercentage(this.userData.id);
            this.posts = this.posts.filter(item => item.id !== this.postIdToDelete);
            this.loadingPosts = false;
          })
          .catch((error) => {
            this.loadingPosts = false;
            this.showError(error);
          });
        this.onDeleteClose();
      }
    }
  };
</script>
