<template>
  <v-expansion-panel>
      <v-expansion-panel-header>
          {{ $t('posts') }}
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-row>
            <v-col col="12">
                <v-btn @click="openPostDialog" color="bg-green-prosk" small class="white--text">
                    {{ $t("createPost") }}
                </v-btn>
                <v-btn @click="openDeleteDialog" color="ml-4 bg-green-prosk" small class="white--text">
                    {{ $t("deletePost") }}
                </v-btn>
            </v-col>
        </v-row>
        <CreatePostAdminDialog :showDialog="showPostDialog" :userData="userData" :isLoading = false :onClose="closePostDialog"/>
        <DeletePostAdminDialog :showDialog="showDeleteDialog" :userData="userData" :isLoading = false :onClose="closeDeleteDialog"/>
      </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import CreatePostAdminDialog from '@/components/socialvue/dialogs/Admin/CreatePostAdminDialog';
import DeletePostAdminDialog from '@/components/socialvue/dialogs/Admin/DeletePostAdminDialog';
export default {
    name: 'UserPostPanel',
    components: {
        CreatePostAdminDialog,
        DeletePostAdminDialog
    },
    props: {
        userData: {
            type: Object,
            required: true
        },
        isLoading: {
            type: Boolean,
            required: true
        }
    },
    data () {
        return {
            showPostDialog: false,
            showDeleteDialog: false
        }
    },
    methods: {
        openPostDialog () {
            this.showPostDialog = true;
        },
        closePostDialog () {
            this.showPostDialog = false;
        },
         openDeleteDialog () {
            this.showDeleteDialog = true;
        },
        closeDeleteDialog () {
            this.showDeleteDialog = false;
        }
    }
}
</script>
