<template>
<v-autocomplete
            :items="categories"
            v-model="categoriesSelected"
            color="#59D79D"
            item-color="#59D79D"
            item-text="name"
            item-value="id"
            chips
            multiple
            solo
            :search-input.sync="searchInput"
            @change="searchInput = ''"
            :loading="isLoading"
            :disabled="disabled"
            @input="$emit('onCategoriesSelectedChange', categoriesSelected)"
            :rules="rules"
          >
            <template v-slot:item="data">
              <v-list-item-content>
                <v-list-item-subtitle>
                  <b>{{ getCategorySubcategoryName(data.item) }}</b>
                </v-list-item-subtitle>
              </v-list-item-content>
            </template>
            <template #selection="{ item }">
              <v-chip color="#59D79D" class="white--text">{{item?.name}}</v-chip>
            </template>
          </v-autocomplete>
</template>
<script>
import axios from 'axios';
import _debounce from 'lodash/debounce';

export default {
    name: 'CategoriesAutoComplete',
    props: ['disabled', 'userCategoriesIds', 'rules'],
    data () {
        return {
            categories: [],
            searchInput: '',
            isLoading: false,
            loading: false,
            categoriesSelected: this.userCategoriesIds || []
        };
    },
    methods: {
        getCategorySubcategoryName (item) {
            return item?.category_name + ' - ' + item?.name;
        },
        getCategoriesData: _debounce(function () {
          this.isLoading = true;
          const url = (process.env.VUE_APP_BASE_URL + `/api/categories?filters=parent_id=notNull&per_page=150&search=${this.searchInput === null
              ? '' : this.searchInput}&page=1`).trim();
          axios.get(url).then((response) => {
            this.categories = response.data.data;
            this.isLoading = false;
          })
          .catch((error) => {
            this.showError(error);
            this.isLoading = false;
          });
      }, 500)
    },
    created () {
    this.getCategoriesData();
  }
}
</script>
