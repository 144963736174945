<template>
<v-dialog v-model="showDialog" width="500" persistent @keydown.esc="onClose">
    <v-card>
        <v-card-title class="text-h5 bg-prosk-secondary mb-4">
            <span class="headline">
                {{ $t("createPost") }}
            </span>
        </v-card-title>
        <v-card-text>
        <v-col cols="12">
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="title"
                outlined
                dense
                :placeholder="$t('titlePost')"
                color="gray_stroke"
                class="input-text"
              ></v-text-field>
              <v-text-field
                v-model="content"
                :placeholder="$t('contentPost')"
                outlined
                dense
                :hide-details="contentErrors.length === 0"
                :error-count="contentErrors.length"
                :error-messages="contentErrors"
                color="gray_stroke"
                class="input-text"
              ></v-text-field>
              <div class="d-flex flex-column mt-4">
                <v-file-input
                  v-if="!url_video"
                  class="custom-file-field secondary-prosk"
                  dense
                  @change="handleFileUpload"
                  accept="image/*"
                  v-model="url_image"
                  :placeholder="$t('noSelectedFiles')"
                  prepend-icon=""
                  ref="imageInput"
                >
                  <template v-slot:prepend-inner>
                    <button class="file-button main_text_color--text" @click="fileInputClick('imageInput')">
                      <v-icon size="16px">mdi-plus-circle-outline</v-icon>
                      <span>{{ $t('clickToAddImage') }}</span>
                    </button>
                  </template>
                </v-file-input>

                <v-file-input
                  v-if="!url_image"
                  :rules="videoRules"
                  class="custom-file-field secondary-prosk"
                  dense
                  @change="handleFileUpload"
                  accept="video/*"
                  v-model="url_video"
                  :placeholder="$t('noSelectedFiles')"
                  prepend-icon=""
                  ref="videoInput"
                >
                  <template v-slot:prepend-inner>
                    <button class="file-button main_text_color--text" @click="fileInputClick('videoInput')">
                      <v-icon size="16px">mdi-plus-circle-outline</v-icon>
                      <span>{{ $t('clickToAddVideo') }}</span>
                    </button>
                  </template>
                </v-file-input>
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <cancel-button :cancel="onClose" :message="$t('cancel')" />
              <general-button
                class="ml-4"
                secondary
                responsive
                :action="createPost"
                :loading="loading"
              >
                {{ $t("post") }}
              </general-button>
            </v-col>
          </v-row>
        </v-col>
        </v-card-text>
    </v-card>
</v-dialog>
</template>

<script>
  import axios from 'axios';
  import verifyImageSize from '@/components/socialvue/mixins/verifyImageSize';
  import GeneralButton from '@/components/socialvue/buttons/GeneralButton';
  import CancelButton from '@/components/socialvue/buttons/CancelButton.vue';
  import errorDialog from '@/components/socialvue/mixins/errorDialog';
  export default {
    name: 'CreatePostAdminDialog',
    props: ['showDialog', 'userData', 'onClose', 'isLoading'],
    components: {
        CancelButton, GeneralButton
    },
    mixins: [verifyImageSize, errorDialog],
    data () {
        return {
          contentErrors: [],
          loading: false,
          title: '',
          content: '',
          url_image: null,
          url_video: null,
          url: null,
          user_id: null,
          videoRules: [
            v => !v || this.verifyVideoSize(v)
          ]
        };
    },
    created () {
        if (this.userData && this.userData.id) {
          this.user_id = this.userData.id;
        }
    },
    methods: {
        verifyVideoSize (event) {
          this.file = event;
          if (this.file.size > (20 * 1024 * 1024)) {
            this.$swal.fire({
              title: this.$t('ohNo'),
              text: this.$t('videoFileSizeToBig'),
              confirmButtonText: this.$t('accept')
            });

            this.url_video = null;
          }
        },
        resetPostForm () {
          this.title = '';
          this.content = null;
          this.url_image = null;
          this.url_video = null;
        },
        getFormData () {
          const formData = new FormData();
          if (this.user_id != null) {
            formData.append('user_id', this.user_id);
          }
          if (this.url_image != null) {
            formData.append('url_image', this.url_image);
          }
          if (this.url_video != null) {
            formData.append('url_video', this.url_video);
          }
          formData.set('title', this.title);
          formData.set('content', this.content);
          formData.set('user_id', this.user_id);
          return formData;
        },
        createPost () {
          this.contentErrors = [];
          if (!this.content) {
            this.contentErrors.push(this.$t('contentRequired'));
          } else {
            this.loading = true;
            const formData = this.getFormData();
            const url = process.env.VUE_APP_BASE_URL + '/api/posts';
            axios
              .post(url, formData)
              .then((response) => {
                this.resetPostForm();
                this.posts = [response.data.data].concat(this.posts);
                this.loading = false;
                this.$swal.fire({
                    title: this.$t('awesome'),
                    text: this.$t('postCreatedSuccessfully'),
                    confirmButtonColor: '#19AD93'
                });
              })
              .catch((error) => {
                this.loading = false;
                this.showError(error, false, false, false, this.$t('imageSizeWarning'));
              });
          }
        }
    }
  };
</script>
