const mixin = {
    data () {
        return {
            maxFileSize: process.env.VUE_APP_MAX_FILE_SIZE,
            maxVideoSize: (20 * 1024 * 1024),
            canSaveImage: false
        };
    },
    methods: {
        fileInputClick (ref) {
            this.$refs[ref].$refs.input.click();
        },
        handleFileUpload (file, ...args) {
            if (!file) return;
            console.log('file', file, args);

            if (file.size > this.maxFileSize) {
                this.canSaveImage = false;

                let warningTextKey = 'fileSizeWarning';
                if (file.type.includes('image')) warningTextKey = 'imageSizeWarning';
                if (file.type.includes('video')) warningTextKey = 'videoSizeWarning';

                this.$swal.fire({ text: this.$t(warningTextKey) });
                return;
            }
            const reader = new FileReader();
            reader.onloadend = () => {
                this.canSaveImage = true;
            };
            reader.readAsArrayBuffer(file);
        }
    }
};

export default mixin;
